import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../../layouts'
import LargeHero from '../../../components/elements/largeHero'
import { Body, SizedText, Canon } from '../../../components/elements/typography'
import Grid from '../../../components/elements/grid'
import { navigate } from 'gatsby'

import { useForm, useFormState } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"



const ContactMessageSuccessPage = () => {
  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>

      <LargeHero.Container
        padding="3rem 0 4rem 0"
        background={{
          backgroundImage: `url(/images/background-deltas.jpg)`, 
          backgroundRepeat: `no-repeat`, 
          backgroundAttachment: `scroll`, 
          backgroundPosition: `top left`, 
          backgroundSize: `cover`,
        }}
        breadcrumbLinks={[
          { path: "/", text: "Home" },
          { path: "/about/", text: "About Us" },
          { path: "/about/contact", text: "Contact Us" },
          { path: "/about/contact/success", text: "Message Confirmation" },
        ]}
        styles={{
          paddingBottom: `5rem`,
          overflow: `hidden`,
          '.breadcrumbs, .breadcrumbs [aria-current="page"]': {color: `#fff`},
          '.heroContent': { paddingBottom: `3rem`},
          '@media (min-width: 680px)': {
            '.heroContent': { width: `calc(52% - 1rem)`, marginBottom: `0`},
            '.heroImage': { width: `calc(40% - 1rem) !important`,},
          }
        }}
      >
        <LargeHero.Content styles={{paddingTop: `1rem`, color: `#fff`}}>
          <LargeHero.Eyebrow text="About Us" styles={{borderColor: `#fff !important`}}/>
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="4.624">Send us a Message</SizedText>
          </h1>
          </LargeHero.Content>
          <LargeHero.Image >

          </LargeHero.Image>
      </LargeHero.Container>

      <Grid.FullWidthSection css={{padding: `6rem 0`, textAlign: `center`}}>
        <Grid.Block start="3" span="10" css={{paddingBottom: `2rem`}}>
          <Canon>Success, Your Message Was Sent!<br />A Team Member Will Contact You Shortly.</Canon>
        </Grid.Block>
      </Grid.FullWidthSection>

    </Layout>
  )
}

export default ContactMessageSuccessPage